<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/account.css";
</style>
<style type="text/css" scoped>
  .custom-file-label::after {
    display: none;
  }

  .el-form-item {
    margin-bottom: unset;
  }

  /deep/ .el-input__inner {
    border: none;
    height: 100%;
    line-height: 0;
  }
</style>
<script>
  import $ from "jquery";
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import {
    sendPhoneCode,
    getcode,
    codeExpired
  } from "@/api/index.js"
  import {
    getSelfDetails,
    updateEmail,
    updatePassword,
    updatePhone
  } from "@/api/admin/user/self";
  import {
    setStore
  } from "@/libs/storage";

  /**
   * PersonData--imgage
   */
  export default {
    components: {
      Layout,
      PageHeader,


    },
    data() {
      return {
        title: "个人资料完善",
        items: [{
            text: "资料完善"
          },
          {
            text: "基本信息",
            active: true
          }
        ],
        email: "",
        isPhone: false,
        mobilePhone: "",
        verification: "",
        registerUser: {},
        isShow: false,
        changePhone: false,
        imgCode: {
          uuid: "",
          img: "",
          code: "",
        },
        phoneCode: "",
        passWordForm: {
          ymm: "",
          xmm: "",
          qrmm: ""
        },
        userDetails: {

        }
      };
    },
    methods: {
      /* 发送手机验证码*/
      sendPhoneCode: function(formDate) {
        return sendPhoneCode(formDate);
      },
      // 验证码是否过期
      isCodeExist() {
        codeExpired({
          mobilePhone: this.mobilePhone
        }).then((res) => {
          if (res.status) {
            if (res.data) { //已过期可重发
              this.getImageCode();
              this.isShow = true;
            } else {
              this.$confirm("验证码尚未过期, 是否重新获取?", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                .then(() => {
                  this.getImageCode();
                  this.isShow = true;
                })
                .catch(() => {
                  return;
                });
            }
          }
        });
      },
      // 显示图片验证
      showImgCode() {
        if (this.mobilePhone) {
          this.checkPhone(); //验证手机号码

        } else {
          this.$message({
            type: 'warning',
            message: '请先输入要更换的手机号'
          });
          return
        }
        if (this.isPhone && !this.committingPhoneCode) {
          this.verification = "",
            this.isCodeExist()
        }

      },
      /*获取验证码*/
      getCode: function() {
        this.checkPhone(); //验证手机号码
        var _this = this
        if (this.isPhone && !this.committingPhoneCode) {
          /*如果状态正确*/
          var formDate = {
            mobilePhone: this.mobilePhone,
            code: this.verification,
            uuid: this.imgCode.uuid
          }
          this.committingPhoneCode = true
          this.sendPhoneCode(formDate).then(res => {
            if (res.status) {
              _this.resetCode(60); //倒计时
              this.isShow = false
              _this.$notify({
                title: '成功',
                message: res.message,
                type: 'success'
              });
            } else {
              // this.imgCodeChange();
              _this.resetCode(5);
            }

          })
        } else {
          $('#phone').focus();
        }

      },
      //倒计时
      resetCode: function(second) {
        var timer = null;
        var _this = this;
        timer = setInterval(function() {
          second -= 1;
          if (second > 0) {
            $('.get-code').css('cursor', 'no-drop')
            $('.get-code').text(second + '秒')
          } else {
            clearInterval(timer);
            $('.get-code').css('cursor', 'pointer')
            $('.get-code').text('点击获取验证码')
            _this.committingPhoneCode = false
          }
        }, 1000);
      },
      changeXmm: function() {
        let res = this.chkPassword()
        if (!res.code) {
          this.$notify({
            title: '警告',
            message: res.message,
            type: 'warning'
          });
          return
        }
      },
      blurQrXmm: function() {
        let res = this.chkRePassword()
        if (!res.code) {
          // res.messsage
          this.$notify({
            title: '警告',
            message: res.message,
            type: 'warning'
          });
          return
        }
      },
      // 密码长度验证
      chkPassword: function() {
        var password = this.passWordForm.xmm;
        if (password.length >= 6) {
          return {
            code: true,
            message: ""
          };
        }
        return {
          code: false,
          message: "密码长度不能小于6位"
        };
      },
      // 验证第二次输入的密码
      chkRePassword: function() {
        var rePassword = this.passWordForm.qrmm;
        if (rePassword.length >= 6 && rePassword === this.passWordForm.xmm) {
          return {
            code: true,
            message: ""
          };
        }
        return {
          code: false,
          message: "两次密码不一致"
        };
      },
      // 验证邮箱
      chkEmai: function() {
        var email = this.email;
        var regex = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/g;
        if (regex.test(email)) {
          return {
            code: true,
            message: ""
          };
        }
        return {
          code: false,
          message: "请输入正确的邮箱地址"
        };
      },
      // 验证手机
      checkPhone: function() {
        var pattern = /^1[0-9]{10}$/;
        this.isPhone = true;
        if (this.mobilePhone === '') {
          this.$notify({
            title: '失败',
            message: '请输入手机号码',
            type: 'error'
          });
          this.isPhone = false;
          return this.isPhone;
        }
        if (!pattern.test(this.mobilePhone)) {
          this.$notify({
            title: '失败',
            message: '请输入正确的手机号码',
            type: 'error'
          });
          this.isPhone = false;
          return this.isPhone;
        }
      },
      blurEmail: function() {
        let res = this.chkEmai()
        if (!res.code) {
          // res.messsage
          this.$notify({
            title: '警告',
            message: res.message,
            type: 'warning'
          });
          return
        }
      },
      // 添加邮箱
      submitEmail: function() {
        var _this = this;
        let result = this.chkEmai();
        if (result.code) {
          updateEmail(this.email).then(res => {
            if (res.status) {
              _this.$notify.success({
                title: "成功",
                message: "修改成功",
              });

            }
          })
        }

      },
      // 获取算式验证码
      getImageCode: function() {
        var _this = this;
        getcode().then((res) => {
          if (res.status) {
            _this.imgCode.uuid = res.data.uuid;
            _this.imgCode.img = res.data.img;
          }
        });
      },

      // 修改手机号
      updatePhone() {
        if (this.mobilePhone != "" && this.phoneCode != "") {
          updatePhone(this.mobilePhone).then(res => {
            if (res.status) {
              this.$notify.success({
                title: "成功",
                message: "修改成功",
              })
              this.registerUser['yddh'] = this.mobilePhone
              setStore("userInfo", JSON.stringify(this.registerUser))
              this.changePhone = false
            }
          })
        } else {
          this.$message({
            type: 'warning',
            message: '请先输入要更换的手机号'
          });
        }
      },
      // 修改密码
      updatePassword() {
        var _this = this;
        let res = {}
        res = this.chkPassword()
        if (!res.code) {
          this.$notify({
            title: '警告',
            message: res.message,
            type: 'warning'
          });
          return
        }
        res = this.chkRePassword()
        if (!res.code) {
          this.$notify({
            title: '警告',
            message: res.message,
            type: 'warning'
          });
          return
        }
        updatePassword(
          _this.passWordForm.ymm,
          _this.passWordForm.xmm
        ).then(res => {
          if (res.status) {
            _this.$notify.success({
              title: "成功",
              message: "密码修改成功",
            });
            _this.$router.push("/");
          }
        })
      },
      changeShow() {
        this.mobilePhone = ''
        this.phoneCode = ''
        this.changePhone = true
      },
      // 个人详情
      getUserDetails() {
        getSelfDetails().then(res => {
          if (res.status) {
            this.userDetails = res.data

          }
        })
      }
    },
    mounted() {
      this.getUserDetails()
      this.registerUser = JSON.parse(localStorage.getItem("userInfo"));
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px;">
          <div class="card-body">
            <h4 class="card-title pb-3 border-dash"><span class="mr-2">个人账号管理</span>
            </h4>
            <div>

              <div class="site-content ">
                <div class="module-header ">
                  <div class="title">
                    <span class="text-dark">{{userDetails.xm}}</span><span>（账号：{{userDetails.yhzh}}）
                    </span>
                    <span v-if="userDetails.zw">/
                      {{userDetails.zw}}</span>
                  </div>

                </div>

                <div class="module-body ">
                  <div class="account-container ">
                    <div class="accordion-panel">
                      <div class="account-left">
                        <h5>手机号</h5>
                        <p>
                          {{ userDetails.yddh ? userDetails.yddh : "暂无绑定手机号" }}
                        </p>
                      </div>
                      <div>
                        <b-button variant="outline-info" @click="changeShow">更换</b-button>
                        <b-modal id="change-phone" v-model="changePhone" title="更换手机号" title-class="font-18" centered
                          class="person-modal" hide-footer>
                          <div class="title-tips">{{userDetails.yddh?`你当前手机号为 ${userDetails.yddh}，确认要修改吗？`:'绑定手机号'}}
                          </div>
                          <div class="item-box"><span class="blue-font font-width">新手机号
                            </span><input type="text" placeholder="请输入新手机号码" id="phone" class="modal-input" required
                              v-model="mobilePhone" @blur="checkPhone">
                          </div>
                          <div class="item-box"><span class="blue-font font-width get-code" @click="showImgCode">点击获取验证码

                            </span><input type="text" placeholder="请输入接收到的短信验证码" v-model="phoneCode" class="modal-input"
                              required>
                          </div>
                          <div><button type="button" class="btn  btn-info change-btn" @click="updatePhone">确定修改</button>
                          </div>

                        </b-modal>
                      </div>
                    </div>

                    <div class="accordion-panel">
                      <div class="account-left">
                        <h5>电子邮箱</h5>
                        <p>{{userDetails.dzxx?userDetails.dzxx:'目前尚未添加，点击添加'}}</p>

                      </div>
                      <div>
                        <b-button v-b-modal.change-email variant="outline-info">{{userDetails.dzxx?'修改':'添加'}}
                        </b-button>
                        <b-modal id="change-email" title="更改电子邮箱" title-class="font-18" centered class="person-modal"
                          hide-footer>
                          <div class="title-tips">你可以非常方便的接收电子邮件通知</div>
                          <div class="item-box"><span class="blue-font font-width">邮箱地址</span><input type="email"
                              placeholder="请输入电子邮箱地址" class="modal-input" required v-model="email" @blur="blurEmail">
                          </div>
                          <div class="w-100"><button type="button" class="btn  btn-info change-btn"
                              @click="submitEmail">确定{{userDetails.dzxx?'修改':'添加'}}</button></div>

                        </b-modal>
                      </div>

                    </div>

                    <!-- <div class="accordion-panel">
                      <div class="account-left">
                        <h5>微信绑定</h5>
                        <p>目前尚未添加，点击添加</p>
                        <p><img src="@/assets/images/person/fa-weixin.png" alt="" class="mr-2"><a href="#"
                            style="color:#1B67CC">点击绑定微信</a></p>
                      </div>
                    </div> -->



                    <div class="accordion-panel">
                      <div class="account-left">
                        <h5>修改密码</h5>
                        <p>密码已设定，点击修改按钮可重置</p>
                      </div>
                      <div>
                        <b-button v-b-modal.change-password variant="outline-info">修改</b-button>
                        <b-modal id="change-password" title="更改密码" title-class="font-18" centered class="person-modal"
                          hide-footer>
                          <form :model="passWordForm">
                            <div class="item-box"><span class="blue-font font-width">原密码

                              </span><input type="password" placeholder="请输入原密码" v-model="passWordForm.ymm"
                                class="modal-input" required>
                            </div>


                            <div class="item-box"><span class="blue-font font-width">新密码
                              </span><input type="password" placeholder="请输入新密码" v-model="passWordForm.xmm"
                                class="modal-input" required @change="changeXmm">
                            </div>

                            <div class="item-box"><span class="blue-font font-width">确认新密码

                              </span><input type="password" placeholder="请再次输入新密码" v-model="passWordForm.qrmm"
                                class="modal-input" required @blur="blurQrXmm">
                            </div>

                            <div class="w-100"><button type="button" class="btn  btn-info change-btn"
                                @click="updatePassword">确定修改</button>
                            </div>
                          </form>
                        </b-modal>
                      </div>
                    </div>

                    <div class="accordion-panel">
                      <div class="account-left">
                        <h5>最近登录</h5>
                        <p> 你最近一次登录系统是 {{ registerUser.zhdlsj }}
                          <span v-if="registerUser.zhdldq">来源于 {{ registerUser.zhdldq }}</span>
                        </p>
                      </div>
                    </div>


                  </div>
                </div>
              </div>

            </div>








          </div>
        </div>
      </div>


    </div>
    <b-modal id="imgCheck" v-model="isShow" title="获取短信验证码" title-class="font-18" centered class="person-modal"
      hide-footer>
      <div class="input-group" style="height: 45px">
        <div class="input-group-prepend">
          <span class="input-group-text icon-box"><img src="@/assets/images/person/icon/security.png" alt="" /></span>
        </div>
        <el-input id="code" type="text" placeholder="请输入算式验证码" class="form-control h-100" v-model="verification" />
        <img :src="imgCode.img" class="check-img" @click="getImageCode" />
      </div>
      <div class="w-100 mt-2">
        <button type="button" class="btn btn-info change-btn" @click="getCode">
          获取短信验证码
        </button>
      </div>
    </b-modal>
  </Layout>
</template>
